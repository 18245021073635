'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaDispatching.filters:IncidentSubtypeFilter

 # @description

###
angular
  .module 'lpaDispatching'
  .filter 'incidentSubtypeFilter', [
    () ->
      return (input, parentId) ->
        return _.filter input, (task) ->
          if task.parentIncidentType?
            return task.parentIncidentType.id == parentId
  ]
